<template>
  <div
      class="pages-wrap"
      :class="[mobileMenu === true? 'active-mobile': null]"
      :style="[activeRoute === '/'? styleHome: null]"
  >
    <div class="pages-wrap__top" v-if="activeRoute !== '/'">
      <img src="../../assets/img/page-top-img.jpg" alt="img">
      <div class="container__top">
        <div class="pages-wrap__title" :class="classPage">{{ titlePage }}</div>
      </div>
    </div>
    <div
        :style="[activeRoute === '/'? styleHome: null]"
        :class="{ 'container-full': activeRoute === '/' }"
        class="container"
    >
      <router-view/>
    </div>
    <Footer v-if="activeRoute !== '/'"/>
  </div>
</template>

<script>
import Footer from "@/components/Footer/Footer";

export default {
  props: ["mobileMenu"],
  components: {
    Footer
  },
  data() {
    return {
      activeRoute: '',
      styleHome: {
        minHeight: '100vh',
        marginTop: '0',
        padding: '0',
      },
      titlePage: '',
      classPage: ''
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);

  },
  mounted() {
      document.querySelector('.pages-wrap').classList.add("margin-top")
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.innerWidth < 1456) {
        if (window.pageYOffset > 0) {
          document.querySelector('.pages-wrap').classList.add("margin-top")
        } else {
          document.querySelector('.pages-wrap').classList.remove("margin-top")
        }
      }
    }
  },
  watch: {
    $route(to) {
      this.titlePage = to.meta.title
      this.classPage = to.meta.class
      this.activeRoute = to.path
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/var-style";

.container__top {
  display: flex;
  align-items: flex-end;
  padding: 42px 0 62px 143px;
}

.margin-top {
  margin-top: 80px;
}

.pages-wrap {
  position: relative;
  margin-left: 256px;
  min-width: calc(100vw - 273px);
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: margin-left .3s ease-in-out;
  margin-top: 80px;

  .container {
    width: calc(100vw - 256px);
    padding: 0;
  }

  &__top {
    display: flex;
    align-items: flex-end;
    height: 288px;
    width: 100%;
    position: relative;
    background: $background-main;

    img {
      //width: 100%;
      min-width: 100%;
      object-fit: cover;
      min-height: 100%;
      position: absolute;
    }
  }

  &__title {
    //font-family: 'Stapel', sans-serif;
    font-family: 'Stapel Semi Expanded', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 115%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    z-index: 1;
    width: min-content;
    margin-left: 1px;

    &.no-wrap {
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 1551px) {
  .container__top {
    padding: 42px 0 64px 48px;
  }
}

@media screen and (max-width: 1216px) {
  .container__top {
    padding: 42px 0 64px 80px;
  }
}

@media screen and (max-width: 896px) {
  .container__top {
    max-width: 640px;
    padding: 42px 0 64px 64px;
  }
}

@media screen and (max-width: $mobile) {
  .container__top {
    max-width: 640px;
    padding: 0 16px 32px;
  }
}


@media screen and (max-width: 1456px) {
  .pages-wrap {
    margin-left: 0;
    margin-top: 64px;
  }
  .page__content-wrapper {
    padding-right: 0;
    margin: 0 auto;
  }
  .pages-wrap .container {
    margin-left: auto;
    margin-right: auto;

    &.container-full {
      width: 100%;
    }
  }

  .margin-top {
    margin-top: 64px;
  }
}

@media screen and (max-width: 1000px) {
  .pages-wrap .container {
    width: 100%;
  }
  .pages-wrap__title {
    &.no-wrap {
      white-space: normal;
    }
  }
}

@media screen and (max-width: 869px) {
  .pages-wrap__top {
    height: 269px;
    overflow: hidden;
  }
}

@media screen and (max-width: 420px) {
  .pages-wrap__top {
    height: 144px;

    img {
      width: auto;
      height: inherit;
      min-height: inherit;
    }
  }
  .pages-wrap__title {
    font-size: 24px;
    line-height: 115%;
    letter-spacing: 1.3px;
  }
  .pages-wrap__top img {
    right: 0;
  }
}
</style>
