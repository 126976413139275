<template>
  <div class="nav-menu"
       v-bind:class="[mobileMenu === true ? 'active-mobile': null]"
       v-bind:style="[activeRoute === '/'? styleHome: null]">
    <div class="nav-menu__head">
         Цифровой брендбук
  </div>
  <div class="nav-menu__mobile-top">
    <button class="nav-btn__mobile" @click="$emit('changeMobile')">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11 11.7071L20.6465 21.3536L21.3536 20.6465L11.7072 11L21.3536 1.35358L20.6465 0.646476L11 10.2929L1.35359 0.646454L0.646484 1.35356L10.2929 11L0.646484 20.6465L1.35359 21.3536L11 11.7071Z"
              fill="#505B66"/>
      </svg>
    </button>
  </div>
  <div class="nav-menu__overflow">
    <div class="nav-menu__mobile-wrapper">
      <div
          v-for="(list, i) of linkList"
          :key="i"
          class="nav-menu__wrapper"
      >
        <div :class="[list.isActive ? 'active' : '']" class="nav-menu__title">
          <button class="text" @click.prevent="changeBtn(i)">
            {{ list.title }}
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 22L24 26" stroke="#848E99" stroke-linecap="square"/>
              <path d="M24 26L28 22" stroke="#848E99" stroke-linecap="square"/>
            </svg>
          </button>
          <div class="nav-menu__list" :class="[list.isActive ? 'active' : '']">
            <router-link
                v-for="(item, inx) of list.arrLink"
                :key="inx"
                :to="{path:item.link}"
                class="nav-menu__item"
                @click.native="changeLink()"
            >
              {{ item.title }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ["mobileMenu"],
  data() {
    return {
      linkList: [
        {
          title: 'Айдентика',
          isActive: true,
          arrLink: [
            {title: 'Логотип', link: '/identity/logo'},
            {title: 'Цвета', link: '/identity/color'},
            {title: 'Типографика', link: '/identity/typography'},
            {title: 'Фирменный стиль', link: '/identity/style'},
            {title: 'Пиктограммы', link: '/identity/picto'},
            {title: 'Паттерны', link: '/identity/patterns'},
            {title: 'Фото и видео', link: '/identity/photo-style'},
            {title: 'Правила коммуникаций', link: '/identity/communication-rules'}
          ]
        },
        {
          title: 'Носители бренда',
          isActive: false,
          arrLink: [
            {title: 'Офисная айдентика', link: '/brand/office-identity'},
            {title: 'Презентации', link: '/brand/presentations'},
            {title: 'Экипировка', link: '/brand/equipments'},
            {title: 'Сувенирная продукция', link: '/brand/souvenir-products'},
            {title: 'Оформление мероприятий', link: '/brand/event-decoration'},
            {title: 'Интерьер', link: '/brand/interior'},
            {title: 'Видео и анимация', link: '/brand/video-and-animation'}
          ]

        }
      ],
      activeRoute: '',
      styleHome: {
        minHeight: '100vh',
        top: '0'
      },
    }
  },
  methods: {
    changeBtn(index) {
      this.linkList.forEach((el, inx) => {
        if (inx === index) {
          el.isActive = !el.isActive
        } else {
          // el.isActive = false
        }
      })
    },
    changeLink() {
      if (this.widthWindow < 1457) {
        this.$emit('changeMobile')
      }
      window.scrollTo(0, 0);
    },
    updateWidth() {
      this.widthWindow = window.innerWidth;
    },
  },
  mounted() {
    this.updateWidth()
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
  },
  watch: {
    $route(to) {
      this.activeRoute = to.path
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/var-style";

* {
  font-family: 'suisse_intl', sans-serif;
}

.search-wrapper {
  display: none;
  align-items: center;
  position: relative;

  .search-icon {
    position: absolute;
  }

  .clear-search {
    position: absolute;
    right: 20px;
    top: 32px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  input {
    border: none;
    width: 100%;
    padding: 31px 16px;
    background: #F2F5F5;
    color: #242B33;
    font-size: 20px;
    line-height: 18px;

    &::placeholder {
      font-size: 20px;
      line-height: 18px;
      color: #242B33;
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }

    &:focus + .search-icon {
      display: none;
    }
  }

}

.nav-menu {
  position: fixed;
  top: 0;
  background: #fff;
  box-shadow: inset -1px 0px 0px #E4E7EB;
  width: 256px;
  max-width: 256px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
  transition: all .3s ease-in-out;
  left: 0;
  z-index: 3;

  &__head {
    padding: 30.5px 34.1px;
    margin-bottom: 0;
    border-bottom: 1px solid $white-polar;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: left;
    color: #242B33;
  }

  &__list,
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__list {
    //height: 0;
    overflow: hidden;
    width: 256px;
    left: -33px;
    top: 2px;
    position: relative;
    transition: all .2s ease-in-out;

    &.active {
      //height: auto;
    }
  }

  &__item {
    width: calc(100% - 1px);
    color: #697480;
    text-decoration: none;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    min-height: 48px;
    font-weight: 200;
    transition: all .4s ease-in-out;
    padding: 11px 32px 14px 32px;

    &.router-link-exact-active {
      background: #F3F8FA;
      color: #242B33;
      line-height: 18px;
      font-weight: 400;
      font-size: 13px;
    }

    &:hover {
      opacity: .5;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 18px;
    padding: 0 32px;
    letter-spacing: 0.03em;
    color: #39434D;
    cursor: pointer;
    background: #fff;
    border: none;
    position: relative;
    max-width: calc(100% - 1px);
    display: flex;
    flex-direction: column;
    height: 77px;
    justify-content: flex-start;

    &:after {
      display: flex;
      content: '';
      height: 1px;
      width: calc(100% - 40px);
      box-shadow: inset -1px 0px 0px #e4e7eb;
      background: #e4e7eb;
      margin-left: 24px;
      margin-top: 5px;
      margin-bottom: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:focus {
      outline: none;
    }

    svg {
      position: absolute;
      right: 0;
      top: 0;
      transition: all .5s ease-in-out;
    }

    .nav-menu__item {
      opacity: 0;
      transition: opacity .5s ease-in-out,
      background-color .5s ease-in-out;
    }

    &.active {
      height: inherit;
      padding-bottom: 13px;

      .nav-menu__item {
        opacity: 1;

        &:hover {
          background: rgba(243, 248, 250, 0.7);
        }
      }

      &:active .text {
        background: #F3F8FA;
        letter-spacing: 0.1px;
        padding: 16px 4px 15px 32px;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.nav-btn__mobile {
  background: #fff;
  border: none;

  :focus {
    outline: none;
  }
}

.nav-menu__mobile-top {
  display: none;
}

.nav-menu__title {
  position: relative;
}

.nav-menu__wrapper:last-child {
  .nav-menu__title {
    height: 80px;

    &.active {
      height: auto;
    }

    &:after {
      display: none;
    }
  }
}

.nav-menu__title {
  transition: height .3s ease-in-out;

  .text {
    margin-top: 16px;
    background: #fff;
    text-align: left;
    border: none;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #39434D;
    cursor: pointer;
    position: relative;
    width: 256px;
    left: -33px;
    padding: 15px 4px 15px 32px;
    transition: all .5s ease-in-out;
    font-weight: normal;
    font-family: 'suisse_intl', sans-serif;

    &:focus {
      outline: none;
    }

    &:hover {
      background: #F3F8FA;
    }
  }
}

.nav-menu__mobile-wrapper {
  height: auto;
}

.nav-menu__wrapper:nth-child(2) {
  .nav-menu__title {
    margin-top: 3px;

    .text {
      margin-top: 0;
    }
  }
}

.nav-menu__wrapper:nth-child(1) {
  .nav-menu__title {
    .text {
      letter-spacing: 0.03em;

    }
  }
}

.nav-menu__overflow {
  height: calc(100vh - 80px);
  overflow: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 1456px) {
  .nav-menu {
    &.active-mobile {
      left: -256px;
    }

    &__overflow {
      height: calc(100vh - 80px);
      overflow: auto;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-width: 767px) {
  .nav-menu__mobile-wrapper {
    //height: calc(100vh - 66px);
    height: auto;
    padding-bottom: 100px;
    overflow: auto;
  }
  .nav-menu.mobile {
    max-width: 100%;
    width: 100%;
    left: 0;
    top: 0;
    min-height: 100%;
  }
  .nav-menu.active-mobile {
    left: -100%;
  }
  .nav-menu__head {
    display: none;
  }
  .nav-menu__head-img {
    width: 100%;
  }
  .search-wrapper {
    display: flex;
  }
  .nav-menu__mobile-top {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .nav-menu {
    width: 100%;
    min-width: 100%;
  }
  .nav-menu__list {
    width: calc(100% + 66px);
  }
  .nav-menu__title .text {
    font-size: 20px;
    line-height: 18px;
    font-weight: 600;
    width: calc(100% + 66px);
  }
  .nav-menu__item {
    height: 64px;
    font-size: 16px;
    line-height: 18px;
  }
  .nav-menu__item.router-link-exact-active {
    font-size: 16px;
    line-height: 18px;
  }
}

@media screen and (max-height: 650px){
  .nav-menu__overflow {
    height: calc(100vh - 80px);
    overflow: auto;
    overflow-x: hidden;
  }
}
</style>
