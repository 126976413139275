import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/Views/Home')
  },
  {
    path: '/identity',
    name: 'Identity',
    component: () => import('@/Views/Identity/Identity'),
    children: [
      {
        path: 'logo',
        name: 'LogoPage',
        meta:{
          title: 'Логотип'
        },
        component: () => import('@/Views/Identity/Pages/LogoPage')
      },
      {
        path: 'color',
        name: 'ColorPage',
        meta:{
          title: 'Цвета'
        },
        component: () => import('@/Views/Identity/Pages/ColorPage')
      },
      {
        path: 'typography',
        name: 'TypographyPage',
        meta:{
          title: 'Типографика'
        },
        component: () => import('@/Views/Identity/Pages/TypographyPage')
      },
      {
        path: 'picto',
        name: 'pictoPage',
        meta:{
          title: 'Пиктограммы'
        },
        component: () => import('@/Views/Identity/Pages/PictoPage')
      },
      {
        path: 'patterns',
        name: 'PatternsPage',
        meta:{
          title: 'Паттерны'
        },
        component: () => import('@/Views/Identity/Pages/PatternsPage')
      },
      {
        path: 'style',
        name: 'StylePage',
        meta:{
          title: 'Фирменный стиль',
          class: 'no-wrap'
        },
        component: () => import('@/Views/Identity/Pages/StylePage')
      },
      {
        path: 'photo-style',
        name: 'PhotostylePage',
        meta:{
          title: 'Фото и видео',
          class: 'no-wrap'
        },
        component: () => import('@/Views/Identity/Pages/PhotostylePage')
      },
      {
        path: 'communication-rules',
        name: 'CommunicationRulesPage',
        meta:{
          title: 'Правила коммуникаций'
        },
        component: () => import('@/Views/Identity/Pages/CommunicationRulesPage')
      }
    ]
  },
  {
    path: '/brand',
    name: 'Brand',
    component: () => import('@/Views/Brand/Brand'),
    children: [
      {
        path: 'office-identity',
        name: 'OfficeIdentityPage',
        meta:{
          title: 'Офисная айдентика'
        },
        component: () => import('@/Views/Brand/Pages/OfficeIdentityPage')
      },
      {
        path: 'presentations',
        name: 'PresentationsPage',
        meta:{
          title: 'Презентации'
        },
        component: () => import('@/Views/Brand/Pages/PresentationsPage')
      },
      {
        path: 'equipments',
        name: 'EquipmentsPage',
        meta:{
          title: 'Экипировка'
        },
        component: () => import('@/Views/Brand/Pages/EquipmentsPage')
      },
      {
        path: 'souvenir-products',
        name: 'SouvenirProductsPage',
        meta:{
          title: 'Сувенирная продукция'
        },
        component: () => import('@/Views/Brand/Pages/SouvenirProductsPage')
      },
      {
        path: 'event-decoration',
        name: 'EventDecorationPage',
        meta: {
          title: 'Оформление мероприятий'
        },
        component: () => import('@/Views/Brand/Pages/EventDecorationPage')
      },
      {
        path: 'interior',
        name: 'InteriorPage',
        meta: {
          title: 'Интерьер'
        },
        component: () => import('@/Views/Brand/Pages/InteriorPage')
      },
      {
        path: 'video-and-animation',
        name: 'VideoAndAnimationPage',
        meta: {
          title: 'Видео и анимация'
        },
        component: () => import('@/Views/Brand/Pages/VideoAndAnimationPage')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes
})

export default router
